<template>
  <TModal
    title="Create organization"
    :show="show"
    @update:show="$emit('update:show', $event)"
  >
    <template #actions>
      <TButtonCreate
        :options="{ disabled: creating }"
        variant="outline"
        @click="create"
      />
    </template>
    <CRow>
      <CCol col="12">
        <TInputText label="ID" :value.sync="data.id" class="mb-2" />
      </CCol>
      <CCol col="12">
        <TInputText label="Name" :value.sync="data.name" class="mb-2" />
      </CCol>
    </CRow>
  </TModal>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      data: {
        id: null,
        name: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      creating: "auth.organizations.creating",
    }),
  },
  methods: {
    create() {
      this.$store.dispatch("auth.organizations.create", this.data);
      this.$emit("update:show", false);
    },
  },
};
</script>
