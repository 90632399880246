<template>
  <div>
    <TTableAdvance
      :items="organizations"
      :fields="fields"
      store="auth.organizations"
      resource="/admin/organizations"
      creatable
      noFilter
      noPaginate
      @click-create="showModalCreate = true"
      deletable
    >
      <template #id="{ item }">
        <td>
          <TMessage :content="item.id" noTranslate />
        </td>
      </template>
      <template #name="{ item }">
        <td>
          <TMessage :content="item.name" noTranslate />
        </td>
      </template>
      <!-- <template #currency="{ item }">
        <td>
          <TMessage
            :content="`${item.currency.name} (${item.currency.id})`"
            noTranslate
          />
        </td>
      </template> -->
    </TTableAdvance>
    <ModalCreateOrganization :show.sync="showModalCreate" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ModalCreateOrganization from "./ModalCreateOrganization.vue";

export default {
  components: { ModalCreateOrganization },
  data() {
    return {
      fields: [
        { key: "_", _style: "width: 20px" },
        { key: "id", label: "Id", _style: "width:200px" },
        { key: "name", label: "Name" },
      ],
      filter: {},
      showModalCreate: false,
    };
  },
  created() {
    this.$store.dispatch("auth.organizations.fetch.if-first-time");
  },
  computed: {
    ...mapGetters({
      organizations: "auth.organizations.list",
    }),
  },
};
</script>
